import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { useEffect, useState } from "react";
import { ROOT_PATH } from "../AppConstants";
import { getDatabase, onValue, ref, remove } from "firebase/database";
import { toast } from "react-toastify";
import HeaderBefore from "../layout/HeaderBefore";
import patternBg from "../assets/pattern-1.svg";
import { useNavigate } from "react-router-dom";

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

const ManageBlogs=()=>{

    const [cards, setCards] = useState([]);
    const db= getDatabase();

    const nav = useNavigate();
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [deleteCardID, setDeleteCardID] = useState(null);

    const openDeleteDialog=(cardId)=>{
      setDeleteDialog(true);
      setDeleteCardID(cardId);
      console.log(cardId);
    };
  
    const handleDeleteClose=()=>{
      setDeleteDialog(false);
    }
  
    const handleDeleteConirm=()=>{
      setDeleteDialog(false);
  
      const loading_toast = toast('Deleting card. Please wait',{
        isLoading: true,
      });
  
      const remove_ref = ref(db, ROOT_PATH + '/blogs/' +deleteCardID);

      remove(remove_ref)
        .then(()=>{
          toast.dismiss(loading_toast);
        }) 
        .catch((ex)=>{
          console.log(ex);
        })
  
      setDeleteCardID(null);
    };

    useEffect(()=>{

        const blog_ref = ref(db, ROOT_PATH + '/blogs');
         

        onValue(blog_ref,(snapshot)=>{
             const snap_val = snapshot.val();
 
             if(snap_val){
                 const keys = Object.keys(snap_val);
                 const values = Object.values(snap_val);
 
 
                 const updatedValues = values.map((value, index) => {
                     return {
                       ...value,
                       blog_id: keys[index]
                     };
                   });
                 const newArray = [...updatedValues];
                 setCards(newArray);
                 console.log(newArray);  
                
             }
              
        });
 
     }, []);


     return(
        <>

        <HeaderBefore />

        <div
            className="whyus-area bg-repeat rounded-[20px]  -mt-5 relative z-10 bg-[rgb(21,21,21)]"
            style={{ backgroundImage: `url(${patternBg})` }}
            >

                    

                    <div className="flex flex-row justify-center w-full">
                        <div className="w-[60%]">
                            <div className="poler-title flex items-center justify-between mb-10">
                                <h2>Manage Blogs ({cards.length})</h2>
                                <a href="/admin-add-blog" className="box-btn gd-btn">
                                <span>Add New Blog</span>
                                </a>
                            </div>
                            <Table>
                                <TableHead className="bg-secondary">
                                    <TableCell>ID</TableCell>
                                    <TableCell>Title</TableCell>
                                    <TableCell>Views</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableHead>

                                <TableBody>
                                    {
                                        cards.map((blog, index)=>{
                                            return (
                                            <TableRow key={index} className="odd:bg-gray-200 even:bg-white">
                                                <TableCell>{blog.blog_id}</TableCell>
                                                <TableCell>{blog.blogTitle}</TableCell>
                                                <TableCell>{blog.views ? Object.keys(blog.views).length : 0}</TableCell>
                                                <TableCell>
                                                    <div className="flex justify-start gap-10"> 
                                                        <a style={{cursor:'pointer'}} onClick={()=>{
                                                            nav('/admin-add-blog', {state: blog});
                                                        }} className="text-blue-500">Edit</a>
                                                        <a style={{cursor:'pointer'}} className="text-red-600" onClick={()=>{
                                                            openDeleteDialog(blog.blog_id);
                                                        }}>Delete</a>
                                                    </div>
                                                    </TableCell>
                                            </TableRow>
                                            );
                                        })
                                    }
                                </TableBody>
                            
                            </Table>
                        </div>
                        
                    </div>
                    

        </div>

            <Dialog open={deleteDialog} onClose={handleDeleteClose}>
              <DialogTitle id="alert-dialog-title">Confirm Delete</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Are you sure you want to delete this item?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleDeleteClose} color="primary">
                  Cancel
                </Button>
                <Button onClick={handleDeleteConirm} color="error" autoFocus>
                  Yes
                </Button>
              </DialogActions>
            </Dialog>

        </>
     );
   


};


export default ManageBlogs;