import { getAuth, onAuthStateChanged } from "firebase/auth";
import Header from "../layout/Header";
import Footer from "../layout/Footer";
import { Checkbox, Field, Label, Select } from "@headlessui/react";
import CountryDropdownWithPhone from "../components/CountryDropdownWithPhone";
import { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { CheckIcon } from "@heroicons/react/20/solid";
import avaterImg from "../assets/upload.jpeg";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { get, getDatabase, onValue, ref, remove, set } from "firebase/database";
import { ROOT_PATH, USERS_PATH, emailValidator, mobileNuberValidator } from "../AppConstants";
import { getDownloadURL, getStorage, ref as StorageRef, uploadBytes } from 'firebase/storage'

import { CKEditor } from '@ckeditor/ckeditor5-react';
import {
  ClassicEditor,
  Bold,
  Essentials,
  Heading,
  Indent,
  IndentBlock,
  Italic,
  Link,
  List,
  MediaEmbed,
  Paragraph,
  Table,
  Undo
} from 'ckeditor5';

import 'ckeditor5/ckeditor5.css'

const AddBlogScreen=()=>{

    const nav = useNavigate();
    const db = getDatabase();
    const storage = getStorage();

    const location = useLocation();
    const state = location.state;

    const [profileImageURL, setProfileImageURL] = useState("https://picsum.photos/600/250");
    const [title, setTitle] = useState("");
    const [subHeading, setSubHeading] = useState("");
    const [blogDescription, setBlogDescription] = useState('<h1>Enter blog description here</h1>');
    const [blogAbstract, setBlogAbstract] = useState('');
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [deleteCardID, setDeleteCardID] = useState(null);

    const openDeleteDialog=(cardId)=>{
      setDeleteDialog(true);
      setDeleteCardID(cardId);
      console.log(cardId);
    };
  
    const handleDeleteClose=()=>{
      setDeleteDialog(false);
    }
  
    const handleDeleteConirm=()=>{
      setDeleteDialog(false);
  
      const loading_toast = toast('Deleting card. Please wait',{
        isLoading: true,
      });
  
      const remove_ref = ref(db, ROOT_PATH + '/blogs/' +deleteCardID);

      remove(remove_ref)
        .then(()=>{
          toast.dismiss(loading_toast);
        }) 
        .catch((ex)=>{
          console.log(ex);
        })
  
      setDeleteCardID(null);
    };
  

      useEffect(()=>{
        console.log(state);
        if(state){
          setProfileImageURL(state.profileImageURL);
          setTitle(state.blogTitle);
          setSubHeading(state.blogSubHeading);
          setBlogDescription(state.blogDescription);
          setBlogAbstract(state.blogAbstract);
        }
      }, []);


      const handleProfileImageChange = async(e) => {
        if (e.target.files[0]) {
    
          const loadingToast = toast('Processing profile image. please wait a moment', {
            type: 'info',
          });
          //setProfileImage(e.target.files[0]);
          console.log(e.target.files[0]);
          const storageRef = StorageRef(storage, `images/${e.target.files[0].name}`);
    
          await uploadBytes(storageRef, e.target.files[0]);
          // Get the download URL
          const downloadURL = await getDownloadURL(storageRef);
          toast.dismiss(loadingToast);
          setProfileImageURL(downloadURL);
        }
      };

      const handleSubmit=()=>{

        if(title.trim() === ''){

          toast('Enter a title', {
            type:'error',
            autoClose: 3000
          });
          return;
        }

        if(subHeading.trim() === ''){

          toast('Enter a subheading', {
            type:'error',
            autoClose: 3000
          });
          return;
        }

        if(blogDescription.trim() === ''){

          toast('Blog description cannot be empty', {
            type:'error',
            autoClose: 3000
          });
          return;
        }

        if(blogAbstract.trim() === ''){

          toast('Blog abstract cannot be empty', {
            type:'error',
            autoClose: 3000
          });
          return;
        }


        const formData = {
          blogTitle: title,
          blogSubHeading: subHeading,
          blogDescription: blogDescription,
          profileImageURL: profileImageURL,
          blogAbstract:blogAbstract
        }

        const ms = state? state.blog_id :new Date().getTime();

        const blog_ref = ref(db, ROOT_PATH + '/blogs/' + ms);

        const loading_toast = toast('Updating. Please wait a moment.',{
          type:'info',
          isLoading:true
        });

        set(blog_ref, formData)
          .then(()=>{
            toast.dismiss(loading_toast);
            toast('Updated successfully',{
              type:'success',
              autoClose: 3000
            });

            nav('/admin-manage-blog');

          })
          .catch((ex)=>{
            toast.dismiss(loading_toast);
            toast('Failed to update. PLease try again',{
              type:'error',
              autoClose: 3000
            })
          })
        

      };

      const handletitleChange=(e)=>{
        setTitle(e.target.value);
      };

      const handleSubHeadingChange=(e)=>{
        setSubHeading(e.target.value);
      };

      const handleblogAbstractChange=(e)=>{
        setBlogAbstract(e.target.value)
      };

      const handleHTMLChange=(event, editor)=>{
        setBlogDescription(editor.getData());
      };


      const fileInputRef = useRef(null);

      const handleImageClick = () => {
        fileInputRef.current.click();
      };

    return(
        <>
            <Header />
            <div className="analytics-area">
                <div className="container">
                    <h1 className="page-title gradient-text type-10">Add New Blog</h1>

                    

                    <div>

                        <form className="w-full auth-form">
                          <div className="mb-10 font-bold text-white">{`Enter banner Image (600px X 250px)`}</div>
                        <input
                                type="file"
                                id="upload-image"
                                ref={fileInputRef}
                                className="max-w-0 max-h-0 absolute"
                                onChange={handleProfileImageChange}
                            />
                        <div className="w-[600px] h-[250px] mb-4" >
                           
                            <img
                                className="w-full h-full border-2 border-[#f6cd66]"
                                src={profileImageURL ? profileImageURL : avaterImg}
                                alt="avaater"
                                
                                onClick={handleImageClick}
                            />
                        </div>
                    
                            <div className="input-inside auth">
                                <input
                                type="text"
                                placeholder="Title of the blog"
                                className="custom-input"
                                value={title}
                                onChange={handletitleChange}

                                />
                            </div>

                            <div className="input-inside auth">
                                <input
                                type="text"
                                placeholder="Sub heading"
                                className="custom-input"
                                value={subHeading}
                                onChange={handleSubHeadingChange}
                                />
                            </div>

                            <div className="input-inside auth">
                                <input
                                type="text"
                                placeholder="Overview of the blog"
                                className="custom-input"
                                value={blogAbstract}
                                onChange={handleblogAbstractChange}
                                />
                            </div>

                            <CKEditor
                                onChange={handleHTMLChange}
                                editor={ ClassicEditor }
                                config={ {
                                  toolbar: [
                                    'undo', 'redo', '|',
                                    'heading', '|', 'bold', 'italic', '|',
                                    'link', 'insertTable', 'mediaEmbed', '|',
                                    'bulletedList', 'numberedList', 'indent', 'outdent'
                                  ],
                                  plugins: [
                                    Bold,
                                    Essentials,
                                    Heading,
                                    Indent,
                                    IndentBlock,
                                    Italic,
                                    Link,
                                    List,
                                    Paragraph,
                                    Table,
                                    Undo
                                  ],
                                  initialData: blogDescription,
                                  
                                } }
                              />

                            <button className="mt-10 box-btn w-full" onClick={(e)=>{
                                e.preventDefault();
                                handleSubmit();
                                }}>Update</button>
                        </form> 

                    </div>

                    <Footer />
                </div>

            </div> 


           
        </>
    )

};


export default AddBlogScreen;