import React, { useEffect, useRef, useState } from "react";
import { CardTable } from "../components/CardTable";
import Header from "../layout/Header";
import Footer from "../layout/Footer";
import { getDatabase, onValue, ref, remove } from "firebase/database";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { ROOT_PATH } from "../AppConstants";

import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  EmailShareButton,
  TelegramShareButton,
  EmailIcon,
  TelegramIcon,
  LinkedinShareButton,
  LinkedinIcon,
  RedditIcon,
  RedditShareButton,
  InstapaperShareButton,
  InstapaperIcon,
} from 'react-share';

import './SharePopup.css'; // Optional for styling
import { QRCodeCanvas } from "qrcode.react";
import { toPng } from "html-to-image";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";


const MyCards = () => {

  const [myCardsList, setMyCardsList] = useState([]);

  const [deleteDialog, setDeleteDialog] = useState(false)
  const [showPopup, setShowPopup] = useState(false);
  const [showQR, setShowQR] = useState(false);
  const db =  getDatabase();
  const auth = getAuth();

  const BASE_URL = "https://visitingcard.brainwavetechz.com/"
  const [shareURL, setShareURL] = useState('');
  const qrRef = useRef();

  

  const [deleteCardID, setDeleteCardID] = useState(null);

  const openDeleteDialog=(cardId)=>{
    setDeleteDialog(true);
    setDeleteCardID(cardId);
    console.log(cardId);
  };

  const handleDeleteClose=()=>{
    setDeleteDialog(false);
  }

  const handleDeleteConirm=()=>{
    setDeleteDialog(false);

    const loading_toast = toast('Deleting card. Please wait',{
      isLoading: true,
    });

    const remove_ref = ref(db, ROOT_PATH + '/cards/' + auth.currentUser.uid + '/mycards/' + deleteCardID);
    remove(remove_ref)
      .then(()=>{
        toast.dismiss(loading_toast);
      }) 
      .catch((ex)=>{
        console.log(ex);
      })

    setDeleteCardID(null);
  };


  useEffect(()=>{
    onAuthStateChanged(auth , (user)=>{

      if(user){
        
        const c_ref = ref(db, ROOT_PATH + "/cards/" + user.uid + '/mycards');

        onValue(c_ref, (snapshot) =>{
          const snapData = snapshot.val();
          if(snapData){
            
            const tmpValues = Object.values(snapData);
            const tmpKeys = Object.keys(snapData);

            const tmpArray = tmpKeys.map((key, index)=>{
                return {uploadId: key, ...tmpValues[index]};
            });

            setMyCardsList(tmpArray);

          }
        });

      }
  });
  },[]);

  const handleShare=(path)=>{
    const finURL = BASE_URL + path;
    //console.log(finURL);
    setShareURL(finURL);
    setShowPopup(true);
  };

  const handleQRShare=(path)=>{
    const finURL = BASE_URL + path;
    //console.log(finURL);
    setShareURL(finURL);
    setShowQR(true);
  };

  const handleDownloadClick = async () => {
    if (qrRef.current) {
      const dataUrl = await toPng(qrRef.current);
      const link = document.createElement('a');
      link.href = dataUrl;
      link.download = 'qr-code.png';
      link.click();
    }
  };

  

  return (
    <>
      <Header />
      <div className="mycard-area">
        <div className="container">
          <div className="poler-title flex items-center justify-between">
            <h2>My Cards ({myCardsList.length})</h2>
            <a href="/create-card" className="box-btn gd-btn">
              <span>Create new card</span>
            </a>
          </div>
          {myCardsList.length> 0 ?<CardTable myCards={myCardsList} handleShare={handleShare} handleQRShare={handleQRShare} handleDeleteClick={openDeleteDialog}/>
            :
            <center className="mt-20 font-bold text-[30px] text-white">No cards created yet. Create one to see your cards here</center>}
        </div>
      </div>

      <div>
        
        {showPopup && (
          <div className="share-popup-overlay" onClick={()=>setShowPopup(false)}>
            <div className="share-popup">
              <h3>Share this card</h3>
              <div className="share-buttons">
                <FacebookShareButton url={shareURL} quote='Have a look at my card'>
                  <FacebookIcon size={32} round />
                </FacebookShareButton>
                <TwitterShareButton url={shareURL} title='Have a look at my card'>
                  <TwitterIcon size={32} round />
                </TwitterShareButton>
                <WhatsappShareButton url={shareURL} title='Have a look at my card'>
                  <WhatsappIcon size={32} round />
                </WhatsappShareButton>

                <EmailShareButton url={shareURL} title='Have a look at my card'>
                  <EmailIcon size={32} round />
                </EmailShareButton>

                <TelegramShareButton url={shareURL} title='Have a look at my card'>
                  <TelegramIcon size={32} round />
                </TelegramShareButton>

                <LinkedinShareButton url={shareURL} title='Have a look at my card'>
                  <LinkedinIcon size={32} round />
                </LinkedinShareButton>

                <RedditShareButton url={shareURL} title='Have a look at my card'>
                  <RedditIcon size={32} round />
                </RedditShareButton>

                <InstapaperShareButton url={shareURL} title='Have a look at my card'>
                  <InstapaperIcon size={32} round />
                </InstapaperShareButton>
              </div>

              
            </div>
          </div>

        )}


      { showQR &&
          <div style={{ textAlign: 'center', marginTop: '20px' , backgroundColor:'#fff', padding:'30px', width: '25%'}}>
          
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
      
          <div ref={qrRef}>
            <QRCodeCanvas value={shareURL} size={256} onClick={()=>setShowQR(false)}/>
          </div>
          </div>
          <button onClick={handleDownloadClick} style={{ marginTop: '20px', padding: '10px 20px' }}>
            Download QR Code
          </button>
        </div>
      }
      </div>


        <Dialog open={deleteDialog} onClose={handleDeleteClose}>
          <DialogTitle id="alert-dialog-title">Confirm Delete</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete this item?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleDeleteConirm} color="error" autoFocus>
              Yes
            </Button>
          </DialogActions>
        </Dialog>

      <Footer />
    </>
  );
};

export default MyCards;
