import { sendPasswordResetEmail, signInWithEmailAndPassword } from "firebase/auth";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { emailValidator } from "../AppConstants";

const SignIn = ({auth}) => {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const nav = useNavigate();


  const handleEmailChange=(e)=>{
    setEmail(e.target.value);
  };

  const handlePasswordChange=(e)=>{
    setPassword(e.target.value);
  };

  const handleSignin=()=>{

    const auth_toast = toast('Signing in...',{
      type: 'info',
      isLoading: true
    })

    signInWithEmailAndPassword(auth, email, password)
        .then((creds) =>{
          toast.dismiss(auth_toast);
          console.log('login success' + creds.user.email);
          nav('/create-card');
        }) 
        .catch((ex)=>{
          toast.dismiss(auth_toast);
          toast('Failed to login. Cause: ' + ex,{
            type:'error',
            autoClose: 3000
          });
          console.error(ex);
        });
  };


  return (
    <form className="w-full auth-form">
      <div className="input-inside auth">
        <input
          type="text"
          placeholder="Email address"
          className="custom-input"
          onChange={handleEmailChange}
        />
      </div>
      <div className="input-inside auth">
        <input
          type="text"
          placeholder="Enter your password"
          className="custom-input"
          onChange={handlePasswordChange}
        />
      </div>
      <button className="box-btn w-full" onClick={(e)=>{
        e.preventDefault();
        handleSignin();
      }}>Login</button>
      <a
        href="#"
        className="text-[#a3a3a3] text-[13px] font-medium font-poppins transition duration-200 hover:text-[#69b1ff] mt-4 inline-block"
      >
        <div onClick={()=>{

          if(!emailValidator(email)){
            toast('Enter a valid email address and click forgot password to send a reset mail', {
              type: 'error',
              autoClose: 3000
            });
            return;
          }

          const loadingToast = toast('Please wait a moment',{
            type: 'info',
            progress: true
          });

          sendPasswordResetEmail(auth, email)
            .then(()=>{
              toast.dismiss(loadingToast);
              toast(`A password reset mail was sent to ${email}`,{
                type:'success',
                autoClose: 3000
              })
            })
            .catch((ex)=>{
              toast.dismiss(loadingToast);
              toast('Incorrect email or no valid records found for this email',{
                type: 'error',
                autoClose:3000
              })
            })

        }}>Forgot Password?</div>
      </a>
    </form>
  );
};

export default SignIn;
