import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { Checkbox, Field, Label, Select } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/20/solid";
import CountryDropdownWithPhone from "./CountryDropdownWithPhone";
import { getDatabase, ref, set } from "firebase/database";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { toast } from "react-toastify";
import { Dialog } from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";
import { USERS_PATH, emailValidator, mobileNuberValidator } from "../AppConstants";

const SignUp = ({auth}) => {



  const [organizationType, setOrganisationTpe] = useState('organization');
  const [userName, setUserName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [phoneCountry, setPhoneCountry] = useState('IN');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [industry, setIndustry] = useState('');
  const [password, setPassword] = useState('');
  const [confirmed_password, setConfirPassword] = useState('');
  const [agreement, setAgreement] = useState(false);
  

  const db = getDatabase();
  const navigate = useNavigate();
  

  const handleRegistration=()=>{

    

    
    const formData = {
      organizationType : organizationType,
      userName: userName,
      userEmail: userEmail,
      phoneCountry: phoneCountry,
      phoneNumber: phoneNumber,
      industry: industry,
      password: password
    };


   

    if(formData.userName.trim() === ''){
      toast('Username cannot be empty', {
        type: 'error',
        autoClose: 3000
      });
      return;
    }

    if(!emailValidator(formData.userEmail)){
      toast('Enter a valid email address', {
        type: 'error',
        autoClose: 3000
      });
      return;
    }

    if(!mobileNuberValidator(formData.phoneNumber)){
      toast('Enter a valid mobile number', {
        type: 'error',
        autoClose: 3000
      });
      return;
    }

    if(password.trim() !==''){
        if(password === confirmed_password){
          
        } else {
          console.log('different');
          toast('Passwords donot match', {
            type: 'error',
            autoClose: 3000
          });

          return;
        }
    } else{
      console.log('empty');
      toast('Password cannot be empty', {
        type: 'error',
        autoClose: 3000
      });

      return;
    }

    if(!agreement){
      toast('You must agree to our terms and conditions before proceeding', {
        type: 'error',
        autoClose: 3000
      });
      return;
    }

    const regToast = toast('Processing. Please wait...',{
      type:'info',
      isLoading: true
    });

    


    createUserWithEmailAndPassword(auth, formData.userEmail, formData.password)
        .then(async (creds)=>{
          

          const user_ref = ref(db, USERS_PATH + '/' + creds.user.uid);

          await set(user_ref, formData);

          toast.dismiss(regToast);
          toast('Registered successfully',{
            type:'success',
            autoClose: 3000            
          });

          navigate('/create-card');
        })
        .catch((error)=>{
          console.log(error);
          toast.dismiss(regToast);

          switch (error.code) {
            case 'auth/email-already-in-use':
              toast('Registration failed: Email already in use',{
                type:'error',
                autoClose: 3000            
              });
            break;
          }
          
        })
        .finally(()=>{
          //toast.dismiss(regToast);
        })

  };

  const handleNameChange=(e)=>{
    setUserName(e.target.value);
  }

  const handleEmailChange=(e)=>{
    setUserEmail(e.target.value);
  }

  const handlePhoneCountryChange=(e)=>{
    setPhoneCountry(e);
  };

  const handlePhnoChange=(e)=>{
    setPhoneNumber(e.target.value);
  }

  const handleIndustryChange=(e)=>{
    setIndustry(e.target.value);
  }
  
  const handlePasswordChange=(e)=>{
    setPassword(e.target.value);
  }
  
  const handleConfirmPasswordChange=(e)=>{
    setConfirPassword(e.target.value);
  }

  const handleSignup=()=>{
    handleRegistration();

    

  };
  

  return (
    
    <form className="w-full auth-form">
      <div className="input-inside auth">

        <Select
          className={clsx(
            "block w-full appearance-none rounded-[4px] border border-[#4e3002] bg-[#222222] py-1.5 px-3 text-sm text-secondary",
            "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25"
          )}
          onChange={(e)=>{setOrganisationTpe(e.target.value)}}
          value={organizationType}
          >
          <option value="organization">Organization</option>
          <option value="individual">Individual</option>
        </Select>

      </div>
      <div className="input-inside auth">
        <input
          type="text"
          placeholder="Name"
          className="custom-input"

          onChange={handleNameChange}

        />
      </div>

      <div className="input-inside auth">
        <input
          type="email"
          placeholder="Email address"
          className="custom-input"
          onChange={handleEmailChange}
        />
      </div>

      <div className="input-inside auth">
        <CountryDropdownWithPhone handlePhoneCountryChange={handlePhoneCountryChange} handlePhnoChange={handlePhnoChange}/>
      </div>

      <div className="input-inside auth">
        <input
          type="text"
          placeholder="Enter Industry"
          className="custom-input"
          onChange={handleIndustryChange}
        />
      </div>
      
      <div className="input-inside auth">
        <input type="password" placeholder="Password" className="custom-input" onChange={handlePasswordChange}/>
      </div>
      <div className="input-inside auth">
        <input
          type="password"
          placeholder="Confirm Password"
          className="custom-input"
          onChange={handleConfirmPasswordChange}
        />
      </div>

      <Field className="flex items-center gap-2 mb-5">
        <Checkbox
          checked={agreement}
          onChange={setAgreement}
          className="block group size-5 rounded-md bg-white p-1 ring-1 ring-white ring-inset data-[checked]:bg-[#ff9c00]"
        >
          <CheckIcon className="hidden size-3 fill-white group-data-[checked]:block" />
        </Checkbox>

        <Label className="text-white text-sm">
          Accept the{" "}
          <a
            href="#"
            className="text-white text-[13px] font-medium font-poppins transition duration-200 hover:text-[#69b1ff]  inline-block"
          >
            Terms of Use and Privacy Policy.
          </a>{" "}
        </Label>
      </Field>

      <button className="box-btn w-full" onClick={(e)=>{
          e.preventDefault();
          handleSignup();
        }}>Signup</button>
    </form>
  );
};

export default SignUp;
