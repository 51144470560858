import React, { useEffect, useState } from "react";
import { Avatar, Button } from "@material-tailwind/react";
import { NavLink } from "react-router-dom";
import {
  Navbar,
  Collapse,
  Typography,
  IconButton,
} from "@material-tailwind/react";
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from "@headlessui/react";
import logo from "../assets/logo.png";
import activeLink from "../assets/active-link.svg";
import { getAuth, onAuthStateChanged } from "firebase/auth";

const HeaderBefore = ({menuItem}) => {
  const [openNav, setOpenNav] = React.useState(false);

  const auth = getAuth();
  const [user, setUser] = useState(null);

  useEffect(()=>{
    onAuthStateChanged(auth, (user)=>{
      if(user)
        setUser(user);
      else
        setUser(null);

    })
  });

  React.useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setOpenNav(false)
    );
  }, []);

  const navList = (
    <ul className="main-menu mt-2 mb-4 flex flex-col lg:mb-0 lg:mt-0 lg:flex-row lg:items-center">
      <li>
        <NavLink to={'/'}>Home</NavLink>
        {menuItem&& menuItem==1 ?<img
          className="active-sign absolute left-1/2 -translate-x-1/2 bottom-0 w-[calc(100%+8px)] max-w-[calc(100%+8px)]"
          src={activeLink}
          alt=""
        /> : <></>}
      </li>
      <li>
        <NavLink to={'/all-profiles'}>All Profiles</NavLink>
        {menuItem&& menuItem==2 ?<img
          className="active-sign absolute left-1/2 -translate-x-1/2 bottom-0 w-[calc(100%+8px)] max-w-[calc(100%+8px)]"
          src={activeLink}
          alt=""
        /> : <></>}
      </li>
      <li>
        <NavLink to={'/biography'}>Biography</NavLink>
        {menuItem&& menuItem==3 ?<img
          className="active-sign absolute left-1/2 -translate-x-1/2 bottom-0 w-[calc(100%+8px)] max-w-[calc(100%+8px)]"
          src={activeLink}
          alt=""
        /> : <></>}
      </li>
      <li>
        <NavLink to={'/aboutus'}>About Us</NavLink>
        {menuItem&& menuItem==4 ?<img
          className="active-sign absolute left-1/2 -translate-x-1/2 bottom-0 w-[calc(100%+8px)] max-w-[calc(100%+8px)]"
          src={activeLink}
          alt=""
        /> : <></>}
      </li>
      <li>
        <NavLink to={'/contact'}>Contact</NavLink>
        {menuItem&& menuItem==5 ?<img
          className="active-sign absolute left-1/2 -translate-x-1/2 bottom-0 w-[calc(100%+8px)] max-w-[calc(100%+8px)]"
          src={activeLink}
          alt=""
        /> : <></>}
      </li>
    </ul>
  );


  const mobilenavList = (
    <ul className="main-menu mt-20 mb-4 flex flex-col">
      <li className="mb-2 lg:mb-0">
        <NavLink to={'/'} className="block px-4 py-2">Home</NavLink>
      </li>
      <li className="mb-2 lg:mb-0">
        <NavLink to={'/all-profiles'} className="block px-4 py-2">All Profiles</NavLink>
      </li>
      <li className="mb-2 lg:mb-0">
        <NavLink to={'/biography'} className="block px-4 py-2">Biography</NavLink>
      </li>
      <li className="mb-2 lg:mb-0">
        <NavLink to={'/my-cards'} className="block px-4 py-2">My Cards</NavLink>
      </li>
      <li className="mb-2 lg:mb-0">
        <NavLink to={'/aboutus'} className="block px-4 py-2">About Us</NavLink>
      </li>
      <li className="mb-2 lg:mb-0">
        <NavLink to={'/contact'} className="block px-4 py-2">Contact</NavLink>
      </li>
    </ul>
  );


  return (

    <>
    <Navbar className="header-before max-w-full rounded-none shadow-none bg-transparent border-0 px-0 py-3 absolute w-full left-0 right-0 z-20">
      <div className="container mx-auto flex items-center justify-between text-blue-gray-900">
        <Typography
          as="a"
          href="/"
          className="mr-4 cursor-pointer py-1.5 font-medium"
        >
          <img className="w-[130px]" src={logo} alt="logo" />
        </Typography>
        <div className="flex items-center justify-end">
          <div className="hidden lg:block">{navList}</div>
          <div className="hidden lg:block">
            <div className="access-btns-outer">
              <div className="access-btns">
                <a href={user? `/my-cards` :`/auth`}>{user? `My Cards` : `Log-In`}</a>
                <span className="mx-[10px] opacity-60">
                  <img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAAXCAYAAADKmiUPAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAeSURBVHgBvcQxEQAADAKxvyqqEQTiFlSQIejxUbMChCYBQ+JPYSIAAAAASUVORK5CYII="
                    alt=""
                  />
                </span>
                <a href="/create-card">Create your card</a>
              </div>
            </div>
          </div>
        </div>
        <IconButton
          variant="text"
          className="ml-auto h-7 w-7 text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden"
          ripple={false}
          onClick={() => setOpenNav(!openNav)}
        >
          {openNav ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              className="h-6 w-6"
              viewBox="0 0 24 24"
              stroke="#000"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              stroke="#000"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          )}
        </IconButton>
      </div>
      
      

    </Navbar>
    
      <Collapse className="bg-white" open={openNav}>
        <div className="container mx-auto">{mobilenavList}</div>
      </Collapse>
    </>
  );
};

export default HeaderBefore;
