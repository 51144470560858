import { getAuth, onAuthStateChanged } from "firebase/auth";
import Header from "../layout/Header";
import Footer from "../layout/Footer";
import { Checkbox, Field, Label, Select } from "@headlessui/react";
import CountryDropdownWithPhone from "../components/CountryDropdownWithPhone";
import { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { CheckIcon } from "@heroicons/react/20/solid";
import avaterImg from "../assets/upload.jpeg";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { get, getDatabase, onValue, ref, set } from "firebase/database";
import { ROOT_PATH, USERS_PATH, emailValidator, mobileNuberValidator } from "../AppConstants";
import { getDownloadURL, getStorage, ref as StorageRef, uploadBytes } from 'firebase/storage'

const ProfileScreen=()=>{

    const auth = getAuth();
    const nav = useNavigate();
    const db = getDatabase();
    const storage = getStorage();

    const [organizationType, setOrganisationTpe] = useState('organization');
    const [userName, setUserName] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [phoneCountry, setPhoneCountry] = useState('IN');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [industry, setIndustry] = useState('');
    const [password, setPassword] = useState('');
    const [confirmed_password, setConfirPassword] = useState('');
    const [agreement, setAgreement] = useState(false);


    const [userData, setUserData] = useState(null);
    const [profileImageURL, setProfileImageURL] = useState("");


    const handleNameChange=(e)=>{
        setUserName(e.target.value);
      }
    
      const handleEmailChange=(e)=>{
        setUserEmail(e.target.value);
      }
    
      const handlePhoneCountryChange=(e)=>{
        setPhoneCountry(e);
      };
    
      const handlePhnoChange=(e)=>{
        setPhoneNumber(e.target.value);
      }
    
      const handleIndustryChange=(e)=>{
        setIndustry(e.target.value);
      }
      
      const handlePasswordChange=(e)=>{
        setPassword(e.target.value);
      }
      
      const handleConfirmPasswordChange=(e)=>{
        setConfirPassword(e.target.value);
      }


      const handleSubmit=()=>{

        const formData = {
            organizationType : organizationType,
            userName: userName,
            userEmail: userEmail,
            phoneCountry: phoneCountry,
            phoneNumber: phoneNumber,
            industry: industry,
            profileImageURL: profileImageURL,
            password: password
          };
      
      
         
      
          if(formData.userName.trim() === ''){
            toast('Username cannot be empty', {
              type: 'error',
              autoClose: 3000
            });
            return;
          }
      
          if(!emailValidator(formData.userEmail)){
            toast('Enter a valid email address', {
              type: 'error',
              autoClose: 3000
            });
            return;
          }
      
          if(!mobileNuberValidator(formData.phoneNumber)){
            toast('Enter a valid mobile number', {
              type: 'error',
              autoClose: 3000
            });
            return;
          }


          const user_ref = ref(db, USERS_PATH + '/' + auth.currentUser.uid);
          
          const loadingToast = toast('Updating details',{
                type:'info',
                isLoading: true,
          });

          set(user_ref,formData)
            .then(()=>{
                nav('/');
            })
            .catch((ex)=>{
                toast('Failed to update the profile. Please try again',{
                    type:'error',
                    autoClose: 3000
                });
                console.log(ex);
            }).finally(()=>{
                toast.dismiss(loadingToast);
            })

      }

      const handleProfileImageChange = async(e) => {
        if (e.target.files[0]) {
    
          const loadingToast = toast('Processing profile image. please wait a moment', {
            type: 'info',
          });
          //setProfileImage(e.target.files[0]);
          console.log(e.target.files[0]);
          const storageRef = StorageRef(storage, `images/${e.target.files[0].name}`);
    
          await uploadBytes(storageRef, e.target.files[0]);
          // Get the download URL
          const downloadURL = await getDownloadURL(storageRef);
          toast.dismiss(loadingToast);
          setProfileImageURL(downloadURL);
        }
      };

      useEffect(()=>{
        onAuthStateChanged(auth, (user)=>{
            if(user){
                
                console.log(`user.uid : ${user.uid}`);

                const user_ref = ref(db, USERS_PATH + '/' + user.uid)
                
                
               

                onValue(user_ref, (snapshot) =>{
                    const snapVal = snapshot.val();
                    if(snapVal){

                       

                        console.log(snapVal);

                        setOrganisationTpe(snapVal.organizationType);
                        setUserName(snapVal.userName);
                        setUserEmail(snapVal.userEmail);
                        setPhoneNumber(snapVal.phoneNumber);
                        setIndustry(snapVal.industry);
                        setProfileImageURL(snapVal.profileImageURL);

                        
                    }
                });

                

            } else{
                toast('Session expired. Please login',{
                    type:'error',
                    autoClose: 3000
                });

                nav('/auth');
            }
        })
      }, []);

      const fileInputRef = useRef(null);

      const handleImageClick = () => {
        fileInputRef.current.click();
      };

    return(
        <>
            <Header />
            <div className="analytics-area">
                <div className="container">
                    <h1 className="page-title gradient-text type-10">My Profile</h1>

                    

                    <div>

                        <form className="sm:w-full  lg:w-6/12 auth-form">
                        <input
                                type="file"
                                id="upload-image"
                                ref={fileInputRef}
                                className="max-w-0 max-h-0 absolute"
                                onChange={handleProfileImageChange}
                            />
                        <div className="card-avater w-[100px] h-[100px] mb-4" >
                           
                            <img
                                className="w-full h-full rounded-full border-2 border-[#f6cd66]"
                                src={profileImageURL ? profileImageURL : avaterImg}
                                alt="avaater"
                                onClick={handleImageClick}
                            />
                        </div>
                    
                            <div className="input-inside auth">

                                <Select
                                className={clsx(
                                    "block w-full appearance-none rounded-[4px] border border-[#4e3002] bg-[#222222] py-1.5 px-3 text-sm text-secondary",
                                    "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25"
                                )}
                                onChange={(e)=>{setOrganisationTpe(e.target.value)}}
                                value={organizationType}
                                >
                                <option value="organization">Organization</option>
                                <option value="individual">Individual</option>
                                </Select>

                            </div>
                            <div className="input-inside auth">
                                <input
                                type="text"
                                placeholder="Name"
                                className="custom-input"
                                value={userName}
                                onChange={handleNameChange}

                                />
                            </div>

                            <div className="input-inside auth">
                                <input
                                type="email"
                                placeholder="Email address"
                                className="custom-input"
                                value={userEmail}
                                onChange={handleEmailChange}
                                />
                            </div>

                            <div className="input-inside auth">
                                <CountryDropdownWithPhone handlePhoneCountryChange={handlePhoneCountryChange} handlePhnoChange={handlePhnoChange} phoneNumber={phoneNumber}/>
                            </div>

                            <div className="input-inside auth">
                                <input
                                type="text"
                                placeholder="Enter Industry"
                                className="custom-input"
                                value={industry}
                                onChange={handleIndustryChange}
                                />
                            </div>
                            
                            {/*<div className="input-inside auth">
                                <input type="password" placeholder="Password" className="custom-input" onChange={handlePasswordChange}/>
                            </div>
                            <div className="input-inside auth">
                                <input
                                type="password"
                                placeholder="Confirm Password"
                                className="custom-input"
                                />
                            </div>

                            <Field className="flex items-center gap-2 mb-5">
                                <Checkbox
                                checked={agreement}
                                onChange={setAgreement}
                                className="block group size-5 rounded-md bg-white p-1 ring-1 ring-white ring-inset data-[checked]:bg-[#ff9c00]"
                                >
                                <CheckIcon className="hidden size-3 fill-white group-data-[checked]:block" />
                                </Checkbox>

                                <Label className="text-white text-sm">
                                Accept the{" "}
                                <a
                                    href="#"
                                    className="text-white text-[13px] font-medium font-poppins transition duration-200 hover:text-[#69b1ff]  inline-block"
                                >
                                    Terms of Use and Privacy Policy.
                                </a>{" "}
                                </Label>
                            </Field>*/}

                            <button className="box-btn w-full" onClick={(e)=>{
                                e.preventDefault();
                                handleSubmit();
                                }}>Update</button>
                        </form> 

                    </div>

                    <Footer />
                </div>

            </div> 
        </>
    )

};


export default ProfileScreen;