import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "@material-tailwind/react";
import CreateCard from "./pages/CreateCard";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import AboutUs from "./pages/AboutUs";
import Auth from "./pages/Auth";
import MyCards from "./pages/MyCards";
import AllProfiles from "./pages/AllPRofiles";
import Analytics from "./pages/Analytics";
import CardViewer from "./pages/CardViewer";
import { ToastContainer } from "react-toastify";
import ProfileScreen from "./pages/UserProfile.jsx";
import Blogs from "./pages/Blogs.jsx";
import AddBlogScreen from "./pages/BlogAdminAdd.jsx";
import ManageBlogs from "./pages/ManageBlogs.jsx";
import ViewBlog from "./pages/ViewBlog.jsx";

const router = createBrowserRouter([
  {
    path: "/auth",
    element: <Auth />,
  },
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/aboutus",
    element: <AboutUs />,
  },
  {
    path: "/contact",
    element: <Contact />,
  },
  {
    path: "/create-card",
    element: <CreateCard />,
  },
  {
    path: "/all-profiles",
    element: <AllProfiles />,
  },
  {
    path: "/user-profile",
    element: <ProfileScreen />,
  },
  {
    path: "/my-cards",
    element: <MyCards />,
  },
  {
    path: "/analytics",
    element: <Analytics />,
  },
  {
    path: "/biography",
    element: <Blogs />,
  },
  {
    path: "/view-blog",
    element: <ViewBlog />,
  },
  {
    path: "/admin-add-blog",
    element: <AddBlogScreen />,
  },
  {
    path: "/admin-manage-blog",
    element: <ManageBlogs />,
  },
  {
    path: "/*",
    element: <CardViewer />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router}>
      <ThemeProvider>
        <App />      
      </ThemeProvider>
    </RouterProvider>
    <ToastContainer />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
