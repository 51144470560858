import pako from 'pako';

const APP_NAME = "card_connect";


export const ROOT_PATH = APP_NAME;
export const USERS_PATH = APP_NAME + "/users";


 // Convert a base64 string to a CryptoKey
async function base64ToKey(base64) {
    const rawKey = Uint8Array.from(atob(base64), c => c.charCodeAt(0));
    return crypto.subtle.importKey(
      "raw",
      rawKey,
      "AES-CBC",
      true,
      ["encrypt", "decrypt"]
    );
  }
  
  // Predefined key and IV as base64 strings
  const predefinedKeyBase64 = 'mZQx1cW6Vq1Lm1hJNdJm6eN+N5I6+tOix7Z+TMEclhQ=';
  const predefinedIvBase64 = 'WfZ4kLVQXH0b7rU9r+fGhg==';
  
  // Convert predefined base64 strings to CryptoKey and Uint8Array
  const getPredefinedKeyAndIv = async () => {
    const key = await base64ToKey(predefinedKeyBase64);
    const iv = Uint8Array.from(atob(predefinedIvBase64), c => c.charCodeAt(0));
    return { key, iv };
  };
  
  // Encrypt function
    export  async function encryptMyData(data) {
    const { key, iv } = await getPredefinedKeyAndIv();
  
    // Step 1: Serialize the data to JSON
    const jsonString = JSON.stringify(data);
  
    // Step 2: Compress the JSON string using pako
    const compressedData = pako.gzip(jsonString);
  
    // Step 3: Encrypt the compressed data using AES-CBC
    const encryptedData = await crypto.subtle.encrypt(
      {
        name: "AES-CBC",
        iv: iv,
      },
      key,
      compressedData
    );
  
    // Step 4: Encode the encrypted data using Base64
    const base64EncodedData = btoa(String.fromCharCode(...new Uint8Array(encryptedData)));
    return base64EncodedData;
  }
  
  // Decrypt function
 export async function decryptMyData(encodedData) {
    const { key, iv } = await getPredefinedKeyAndIv();
  
    const encryptedData = Uint8Array.from(atob(encodedData), c => c.charCodeAt(0));
  
    // Step 1: Decrypt the data using AES-CBC
    const decryptedData = await crypto.subtle.decrypt(
      {
        name: "AES-CBC",
        iv: iv,
      },
      key,
      encryptedData
    );
  
    // Step 2: Decompress the decrypted data using pako
    const jsonString = pako.ungzip(new Uint8Array(decryptedData), { to: 'string' });
  
    // Step 3: Parse the JSON string back to data
    return JSON.parse(jsonString);
  }


  export function mobileNuberValidator(value){
    const phoneNumberRegex = /^\d{10}$/;
    return phoneNumberRegex.test(value);
  }

  export function emailValidator(value) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(value);
  }
  