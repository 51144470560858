import BlogCard from "../components/BlocgCard";

import patternBg from "../assets/pattern-1.svg";
import HeaderBefore from "../layout/HeaderBefore";
import Footer from "../layout/Footer";
import { useEffect, useState } from "react";
import { getDatabase, onValue, ref } from "firebase/database";
import { ROOT_PATH } from "../AppConstants";
import { toast } from "react-toastify";

const Blogs=()=>{

    const [currentPage, setCurrentPage] = useState(1);
    const cardsPerPage = 5;
  
    const [cards, setCards] = useState([]);

    const db= getDatabase();
  
    const indexOfLastCard = currentPage * cardsPerPage;
    const indexOfFirstCard = indexOfLastCard - cardsPerPage;
    const currentCards = cards.slice(indexOfFirstCard, indexOfLastCard);
  
    const totalPages = Math.ceil(cards.length / cardsPerPage);
  
    const handleNextPage = () => {
      setCurrentPage((prev) => (prev < totalPages ? prev + 1 : prev));
    };
  
    const handlePrevPage = () => {
      setCurrentPage((prev) => (prev > 1 ? prev - 1 : prev));
    };

    useEffect(()=>{

       const blog_ref = ref(db, ROOT_PATH + '/blogs');

       onValue(blog_ref,(snapshot)=>{
            const snap_val = snapshot.val();

            if(snap_val){
                const keys = Object.keys(snap_val);
                const values = Object.values(snap_val);


                const updatedValues = values.map((value, index) => {
                    return {
                      ...value,
                      blog_id: keys[index]
                    };
                  });
                const newArray = [...updatedValues];
                setCards(newArray);
                console.log(newArray);  

            }



       });

    }, []);
  
    return (
      <div>

        <HeaderBefore menuItem={3} />

        <div
            className="whyus-area bg-repeat rounded-[20px]  -mt-5 relative z-10 bg-[rgb(21,21,21)]"
            style={{ backgroundImage: `url(${patternBg})` }}
            >
                <div className="p-10 grid lg:grid-cols-3 xl:grid-col-4 md:grid-cols-2 justify-start gap-[20px]">
                    {currentCards.map((blog, index)=>{
                        return <BlogCard key={index} blog={blog}/>
                    })}
                </div>
                <div className="pagination-controls flex justify-center mt-4">
                <button
                    onClick={handlePrevPage}
                    disabled={currentPage === 1}
                    className="px-4 py-2 mx-2 bg-blue-500 text-white rounded disabled:opacity-50"
                >
                    Previous
                </button>
                <button
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                    className="px-4 py-2 mx-2 bg-blue-500 text-white rounded disabled:opacity-50"
                >
                    Next
                </button>
                </div>

        <Footer />
        </div>
      </div>
    );

};

export default Blogs;