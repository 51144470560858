import { useLocation } from "react-router-dom";
import HeaderBefore from "../layout/HeaderBefore";
import DOMPurify from "dompurify";
import patternBg from "../assets/pattern-1.svg";
import Footer from "../layout/Footer";
import { useEffect } from "react";
import { getDatabase, push, ref } from "firebase/database";
import { ROOT_PATH } from "../AppConstants";

const ViewBlog=()=>{

    const location = useLocation();
    const state = location.state;

    const rawHTML = "<p>This is <strong>raw HTML</strong> content.</p>";
    const sanitizedHTML = DOMPurify.sanitize(state.blogDescription);

    const db = getDatabase();

    useEffect(()=>{
        const blog_ref = ref(db, ROOT_PATH + '/blogs/' + state.blog_id + '/views');

        push(blog_ref,{dateTime:new Date().getTime()});

    }, []);

    return(
        <>
              <HeaderBefore menuItem={3} />

                <div
                    className="whyus-area bg-repeat rounded-[20px]  -mt-5 relative z-10 bg-[rgb(21,21,21)]"
                    style={{ backgroundImage: `url(${patternBg})` }}>

                    <div className="flex flex-row justify-center w-full">

                        <div className="w-[75%] bg-white p-10">
                            <div className="ml-2 mt-2 text-[24px] font-bold text-gray-900">{state.blogTitle}</div>
                            <div className="ml-2 mt-1 text-[15px] text-slate-500">{state.blogSubHeading}</div>
                            <hr className="ml-2 mr-2 mt-5" />
                            <div className="mt-10" dangerouslySetInnerHTML={{ __html: state.blogDescription }} />
                        </div>

                    </div>
                    
                    

                </div>

                <Footer />
        </>
    );
};

export default ViewBlog;