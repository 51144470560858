// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCFEps_NzW_-oaUYY9yBwUwDWMXZF33xp0",
  authDomain: "fantastic-treasure.firebaseapp.com",
  databaseURL: "https://fantastic-treasure-default-rtdb.firebaseio.com",
  projectId: "fantastic-treasure",
  storageBucket: "fantastic-treasure.appspot.com",
  messagingSenderId: "434600177032",
  appId: "1:434600177032:web:627fb82ba1974737129e3d",
  measurementId: "G-13F4T2XS0G"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);