import { useEffect, useState } from "react";
import Header from "../layout/Header";
import { get, getDatabase, ref } from "firebase/database";
import { ROOT_PATH } from "../AppConstants";
import { AllCardTable } from "../components/AllCardTable";
import { EmailIcon, EmailShareButton, FacebookIcon, FacebookShareButton, InstapaperIcon, InstapaperShareButton, LinkedinIcon, LinkedinShareButton, RedditIcon, RedditShareButton, TelegramIcon, TelegramShareButton, TwitterIcon, TwitterShareButton, WhatsappIcon, WhatsappShareButton } from "react-share";
import Footer from "../layout/Footer";
import HeaderBefore from "../layout/HeaderBefore";

const AllProfiles=()=>{

    const db = getDatabase();
    const [cardData, setCardData] = useState([]);

    const [filteredCardList, setFilteredCardList] = useState([]);

    const BASE_URL = "https://visitingcard.brainwavetechz.com/"
    const [shareURL, setShareURL] = useState('');
    const [showPopup, setShowPopup] = useState(false);

    const transformData = (data) => {
        const transformedData = [];
        for (const uid in data) {
          if (data.hasOwnProperty(uid)) {
            const userCards = data[uid].mycards;
            for (const uploadId in userCards) {
              if (userCards.hasOwnProperty(uploadId)) {
                transformedData.push({
                  uid,
                  uploadId,
                  ...userCards[uploadId]
                });
              }
            }
          }
        }
        return transformedData;
      };



      useEffect(()=>{
        const all_cards_ref = ref(db, ROOT_PATH + '/cards')
            get(all_cards_ref)
                .then((snapshot)=>{
                    if(snapshot){
                        const snapVal = snapshot.val();
                        const transformedData = transformData(snapVal);
                        setCardData(transformedData);
                        setFilteredCardList(transformedData);
                        //console.log(transformedData);
                    }
                })
      }, []);


      const handleShare=(path)=>{
        const finURL = BASE_URL + path;
        //console.log(finURL);
        setShareURL(finURL);
        setShowPopup(true);
      };


      const handleNameChange=(e)=>{
        const value = e.target.value;

        // Filter data based on input
        const filtered = cardData.filter(item =>
          item.firstName.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredCardList(filtered);
      };

    return(
        <>
            <HeaderBefore menuItem={2} />
            <div className="analytics-area">
                <div className="container">
                    <div className="h-[150px]"></div>
                    <h1 className="page-title gradient-text type-10">All Profiles</h1>
                    
                    <input
                          type="text"
                          placeholder="Search for names"
                          className="custom-input"
                          onChange={handleNameChange}

                          />
                    <AllCardTable myCards={filteredCardList} handleShare={handleShare}/>

                    <Footer />
                </div>

            </div> 

            {showPopup && (
                <div className="share-popup-overlay" onClick={()=>setShowPopup(false)}>
                    <div className="share-popup">
                    <h3>Share this card</h3>
                    <div className="share-buttons">
                        <FacebookShareButton url={shareURL} quote='Have a look at my card'>
                        <FacebookIcon size={32} round />
                        </FacebookShareButton>
                        <TwitterShareButton url={shareURL} title='Have a look at my card'>
                        <TwitterIcon size={32} round />
                        </TwitterShareButton>
                        <WhatsappShareButton url={shareURL} title='Have a look at my card'>
                        <WhatsappIcon size={32} round />
                        </WhatsappShareButton>

                        <EmailShareButton url={shareURL} title='Have a look at my card'>
                        <EmailIcon size={32} round />
                        </EmailShareButton>

                        <TelegramShareButton url={shareURL} title='Have a look at my card'>
                        <TelegramIcon size={32} round />
                        </TelegramShareButton>

                        <LinkedinShareButton url={shareURL} title='Have a look at my card'>
                        <LinkedinIcon size={32} round />
                        </LinkedinShareButton>

                        <RedditShareButton url={shareURL} title='Have a look at my card'>
                        <RedditIcon size={32} round />
                        </RedditShareButton>

                        <InstapaperShareButton url={shareURL} title='Have a look at my card'>
                        <InstapaperIcon size={32} round />
                        </InstapaperShareButton>
                    </div>

                    
                    </div>
                </div>
            )}
       </>
    );

};


export default AllProfiles;