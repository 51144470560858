import { useNavigate, useParams } from "react-router-dom";
import CardFive from "../components/CardFive";
import CardOne from "../components/CardOne";
import { useEffect, useState } from "react";
import { ROOT_PATH, decryptMyData } from "../AppConstants";
import CardFour from "../components/CardFour";
import CardThree from "../components/CardThree";
import CardTwo from "../components/CardTwo";
import { getAuth } from "firebase/auth";
import { getDatabase, push, ref } from "firebase/database";
import { getAnalytics, logEvent } from "firebase/analytics";
import { EmailIcon, EmailShareButton, FacebookIcon, FacebookShareButton, InstapaperIcon, InstapaperShareButton, LinkedinIcon, LinkedinShareButton, RedditIcon, RedditShareButton, TelegramIcon, TelegramShareButton, TwitterIcon, TwitterShareButton, WhatsappIcon, WhatsappShareButton } from "react-share";

const CardViewer=()=>{

    const params = useParams();
    const path = params['*'];

    const [prefix, setPrefix] = useState('Mr.');
    const [fname, setFname] = useState('');
    const [lname, setLname] = useState('');
    const [suffix, setSuffix] = useState('');
    const [designation, setDesignation] = useState('');
    const [company, setCompany] = useState('');
    const [summary, setSummary] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [website, setWebsite] = useState('');
    const [m_location, setLocation] = useState('');

    const [socialFB, setSocialFB] = useState(false);
  const [socialInsta, setSocialInsta] = useState(false);
  const [socialX, setSocialX] = useState(false);
  const [socialLinkedIn, setSocialLinkedIn] = useState(false);
  const [socialWhatsApp, setWhatsApp] = useState(false);
  const [socialTelegram, setTelegram] = useState(false);
  const [socialTikTok, setTikTok] = useState(false);
  const [socialSnapChat, setSnapChat] = useState(false);

  const [c_fontSize, setFontSize] = useState(15);

  const [fbHandle, setFbHandle] = useState('');
  const [instaHandle, setInstaHandle] = useState('');
  const [xHandle, setXHandle] = useState('');
  const [linkedInHandle, setLinkedInHandle] = useState('');
  const [whatsAppHandle, setWhatsAppInHandle] = useState('');
  const [telegramHandle, setTelegramHandle] = useState('');
  const [tiktokHandle, setTiktokHandle] = useState('');
  const [snapchatHandle, setSnapchatHandle] = useState('');  
  const [coverImageUrl, setCoverImageURL] = useState("");


    const [profileImgURL, setProfilePicURL] = useState(null);

    const [profileImage, setProfileImage] = useState(null);
    const [profileImageURL, setProfileImageURL] = useState("");
    const [cardType, setCardType] = useState(5);

    const navigate = useNavigate();
    const auth = getAuth();
    const db = getDatabase();
    const analytics = getAnalytics();


    const BASE_URL = "https://visitingcard.brainwavetechz.com/"
    const [shareURL, setShareURL] = useState(BASE_URL + path);

    const [showPopup, setShowPopup] = useState(false);
    const handleShare=(path)=>{
        const finURL = BASE_URL + path;
        //console.log(finURL);
        setShareURL(finURL);
        setShowPopup(true);
      };

    const handleDownloadVCard = () => {
        const vCardData = `BEGIN:VCARD
            VERSION:3.0
            FN:${fname}
            ORG:${company}
            TEL;TYPE=WORK,VOICE:${phone}
            EMAIL:${email}
            END:VCARD`;
    
        const blob = new Blob([vCardData], { type: 'text/vcard' });
        const url = URL.createObjectURL(blob);
    
        const a = document.createElement('a');
        a.href = url;
        a.download = 'contact.vcf';
        document.body.appendChild(a);
        a.click();
    
        // Cleanup
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      };

    useEffect(()=>{
        async function decryptCard(){
            try{

                


                const decoded = await decryptMyData(path);
                console.log(decoded);
                
                

                //push(counter_ref, )
                

                setProfileImageURL(decoded.profileImageURL);
                setCoverImageURL(decoded.coverImageURL);
                setPrefix(decoded.prefix);
                setFname(decoded.firstName);
                setLname(decoded.lastName);
                setSuffix(decoded.suffix);
                setCardType(decoded.cardType);
                setDesignation(decoded.designation);
                setCompany(decoded.company);
                setSummary(decoded.summary);
                setWebsite(decoded.website);
                setPhone(decoded.phone);
                setEmail(decoded.email);
                setFbHandle(decoded.fbHandle);
                setSocialInsta(decoded.instaHandle);
                setInstaHandle(decoded.instaHandle);
                setXHandle(decoded.xHandle);
                setTiktokHandle(decoded.tiktokHandle);
                setWhatsAppInHandle(decoded.whatsAppHandle);
                setTelegramHandle(decoded.telegramHandle);
                setLinkedInHandle(decoded.linkedInHandle);
                setLocation(decoded.m_location);

                const counter_ref = ref(db, ROOT_PATH + '/cards/' + decoded.uid + '/mycards/' + decoded.uploadId + '/views');

                const formData = {
                    dateTime : new Date().getTime()
                };

                push(counter_ref, formData);

                logEvent(analytics, 'page_view');

            }catch(ex){
                console.log(ex);
                //navigate('/');
            };
        };

        decryptCard();


    }, []);

    return(

        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh'
          }}>

                {cardType === 1 && <CardOne xHandle={xHandle} linkedInHandle={linkedInHandle} 
                              whatsAppHandle={whatsAppHandle} 
                              telegramHandle={telegramHandle} 
                              tiktokHandle ={tiktokHandle} 
                              snapchatHandle={snapchatHandle}                              
                              instaHandle={instaHandle} 
                              facebookHandle={fbHandle} 
                              coverImageURL={coverImageUrl}
                              profileImageURL={profileImageURL}                                                   
                              fontSize={c_fontSize} 
                              prefix={prefix} 
                              fname={fname} 
                              lname={lname} 
                              suffix={suffix} 
                              designation={designation} 
                              comapnay={company} 
                              summary={summary} 
                              phone={phone} 
                              email={email} 
                              website={website} 
                              m_location={m_location}
                              handleDownloadVCard={handleDownloadVCard}/>}

                  {cardType === 2 && <CardTwo xHandle={xHandle} linkedInHandle={linkedInHandle} 
                                              whatsAppHandle={whatsAppHandle} 
                                              telegramHandle={telegramHandle} 
                                              tiktokHandle ={tiktokHandle} 
                                              snapchatHandle={snapchatHandle}                              
                                              instaHandle={instaHandle} 
                                              facebookHandle={fbHandle} 
                                              coverImageURL={coverImageUrl}
                                              profileImageURL={profileImageURL}                                                   
                                              fontSize={c_fontSize} 
                                              prefix={prefix} 
                                              fname={fname} 
                                              lname={lname} 
                                              suffix={suffix} 
                                              designation={designation} 
                                              comapnay={company} 
                                              summary={summary} 
                                              phone={phone} 
                                              email={email} 
                                              website={website} 
                                              m_location={m_location}
                                              handleDownloadVCard={handleDownloadVCard}
                                              />  }

                  {cardType === 3 && <CardThree xHandle={xHandle} linkedInHandle={linkedInHandle} 
                                                                whatsAppHandle={whatsAppHandle} 
                                                                telegramHandle={telegramHandle} 
                                                                tiktokHandle ={tiktokHandle} 
                                                                snapchatHandle={snapchatHandle}                              
                                                                instaHandle={instaHandle} 
                                                                facebookHandle={fbHandle} 
                                                                coverImageURL={coverImageUrl}
                                                                profileImageURL={profileImageURL}                                                   
                                                                fontSize={c_fontSize} 
                                                                prefix={prefix} 
                                                                fname={fname} 
                                                                lname={lname} 
                                                                suffix={suffix} 
                                                                designation={designation} 
                                                                comapnay={company} 
                                                                summary={summary} 
                                                                phone={phone} 
                                                                email={email} 
                                                                website={website} 
                                                                m_location={m_location}
                                                                handleDownloadVCard={handleDownloadVCard}
                                                                handleShare={handleShare}/> }  

                  {cardType === 4 && <CardFour xHandle={xHandle} linkedInHandle={linkedInHandle} 
                                                                                  whatsAppHandle={whatsAppHandle} 
                                                                                  telegramHandle={telegramHandle} 
                                                                                  tiktokHandle ={tiktokHandle} 
                                                                                  snapchatHandle={snapchatHandle}                              
                                                                                  instaHandle={instaHandle} 
                                                                                  facebookHandle={fbHandle} 
                                                                                  coverImageURL={coverImageUrl}
                                                                                  profileImageURL={profileImageURL}                                                   
                                                                                  fontSize={c_fontSize} 
                                                                                  prefix={prefix} 
                                                                                  fname={fname} 
                                                                                  lname={lname} 
                                                                                  suffix={suffix} 
                                                                                  designation={designation} 
                                                                                  comapnay={company} 
                                                                                  summary={summary} 
                                                                                  phone={phone} 
                                                                                  email={email} 
                                                                                  website={website} 
                                                                                  m_location={m_location}
                                                                                  handleDownloadVCard={handleDownloadVCard}/>   }                      

                    {cardType === 5 && <CardFive xHandle={xHandle} linkedInHandle={linkedInHandle} 
                              whatsAppHandle={whatsAppHandle} 
                              telegramHandle={telegramHandle} 
                              tiktokHandle ={tiktokHandle} 
                              snapchatHandle={snapchatHandle}                              
                              instaHandle={instaHandle} 
                              facebookHandle={fbHandle} 
                              coverImageURL={coverImageUrl}
                              profileImageURL={profileImageURL}                                                   
                              fontSize={c_fontSize} 
                              prefix={prefix} 
                              fname={fname} 
                              lname={lname} 
                              suffix={suffix} 
                              designation={designation} 
                              comapnay={company} 
                              summary={summary} 
                              phone={phone} 
                              email={email} 
                              website={website} 
                              m_location={m_location}
                              handleDownloadVCard={handleDownloadVCard}/>}


{showPopup && (
          <div className="share-popup-overlay" onClick={()=>setShowPopup(false)}>
            <div className="share-popup">
              <h3>Share this card</h3>
              <div className="share-buttons">
                <FacebookShareButton url={shareURL} quote='Have a look at my card'>
                  <FacebookIcon size={32} round />
                </FacebookShareButton>
                <TwitterShareButton url={shareURL} title='Have a look at my card'>
                  <TwitterIcon size={32} round />
                </TwitterShareButton>
                <WhatsappShareButton url={shareURL} title='Have a look at my card'>
                  <WhatsappIcon size={32} round />
                </WhatsappShareButton>

                <EmailShareButton url={shareURL} title='Have a look at my card'>
                  <EmailIcon size={32} round />
                </EmailShareButton>

                <TelegramShareButton url={shareURL} title='Have a look at my card'>
                  <TelegramIcon size={32} round />
                </TelegramShareButton>

                <LinkedinShareButton url={shareURL} title='Have a look at my card'>
                  <LinkedinIcon size={32} round />
                </LinkedinShareButton>

                <RedditShareButton url={shareURL} title='Have a look at my card'>
                  <RedditIcon size={32} round />
                </RedditShareButton>

                <InstapaperShareButton url={shareURL} title='Have a look at my card'>
                  <InstapaperIcon size={32} round />
                </InstapaperShareButton>
              </div>

              
            </div>
          </div>

        )}

        </div>

    );

};


export default CardViewer;