import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
} from 'react-share';

import {
  ChartBarIcon,
  ChevronUpDownIcon,
  EyeIcon,
  IdentificationIcon,
  PencilSquareIcon,
  QrCodeIcon,
  ShareIcon,
  TrashIcon,
} from "@heroicons/react/20/solid";
import {
  Card,
  Typography,
  CardBody,
  Avatar,
  IconButton,
  Tooltip,
} from "@material-tailwind/react";




import Pako from "pako";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { encryptMyData, generateKey } from "../AppConstants";

const TABS = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Monitored",
    value: "monitored",
  },
  {
    label: "Unmonitored",
    value: "unmonitored",
  },
];



const TABLE_HEAD = ["Card", "Views", ""];

const TABLE_ROWS = [
  
];

export function CardTable( {myCards, handleShare, handleQRShare, handleDeleteClick}) {

  
const navigate = useNavigate();
const [enc_key, setEncKey] = useState('');

const nav = useNavigate();


const openNewTab = (url) => {
  const newTab = window.open(url, '_blank');
  if (newTab) {
    newTab.focus();
  } else {
    navigate(url); // Fallback for browsers that block new tab opening
  }
};


  return (
    <Card className="h-full w-full bg-transparent">
      <CardBody className="overflow-x-hidden overflow-y-hidden px-0">
        <table className="mt-4 w-full min-w-max table-auto text-left">
          <thead>
            <tr>
              {TABLE_HEAD.map((head, index) => (
                <th
                  key={head}
                  className="border-x border-[rgb(112,112,112)] bg-[#333] p-4"
                >
                  <Typography
                    variant="small"
                    color="white"
                    className=" leading-none flex items-center justify-between font-semibold"
                  >
                    {head}
                    {index !== TABLE_HEAD.length - 1 && (
                      <ChevronUpDownIcon strokeWidth={2} className="h-4 w-4" />
                    )}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {myCards.map((card, index) => {
              const isLast = index === TABLE_ROWS.myCards - 1;
              const classes = isLast ? "p-4" : "p-4 border-b border-[#333]";

              return (
                <tr key={card.uploadId}>
                  <td className={classes}>
                    <div className="flex items-center gap-3">
                      <Avatar
                        src={card.profileImageURL}
                        alt={card.firstName + ' ' + card.lastName + ' ' + card.suffix}
                        size="sm"
                        className="border border-[#ecc466]"
                      />
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="white"
                          className="font-bold text-sm"
                        >
                          {card.firstName}
                        </Typography>
                      </div>
                    </div>
                  </td>
                  <td className={classes}>
                    <div className="flex ">
                      <Typography
                        variant="small"
                        color="white"
                        className="font-normal text-sm"
                      >
                        {card.views && card.views? Math.floor((Object.keys(card.views).length)/1) : 0}
                      </Typography>
                    </div>
                  </td>
                  <td className={classes}>
                    <div className="actions flex items-center justify-end space-x-3">
                      <Tooltip content="Analytics">
                        <IconButton
                          onClick={()=> navigate('/analytics',{state: card})}
                          variant="text"
                          className="hover:bg-[#333333] rounded-full duration-300 opacity-50 hover:opacity-100"
                        >
                          <ChartBarIcon className="w-4 h-4 text-white" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip content="Open">
                        <IconButton
                          variant="text"
                          className="hover:bg-[#333333] rounded-full duration-300 opacity-50 hover:opacity-100"
                          onClick={async ()=>{
                            const url = await encryptMyData(card);
                            //console.log(url);
                            //navigate(`/${url}`);
                            openNewTab(`/${url}`);
                          }}
                        >
                          <EyeIcon className="w-4 h-4 text-white" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip content="Share link">
                        <IconButton
                          variant="text"
                          className="hover:bg-[#333333] rounded-full duration-300 opacity-50 hover:opacity-100"
                          onClick={async()=>{
                            const url = await encryptMyData(card);
                            handleShare(url);
                          }}
                        >
                          <ShareIcon className="w-4 h-4 text-white" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip content="QR Code">
                        <IconButton
                          variant="text"
                          className="hover:bg-[#333333] rounded-full duration-300 opacity-50 hover:opacity-100"
                          onClick={async()=>{
                            const url = await encryptMyData(card);
                            handleQRShare(url);
                          }}
                        >
                          <QrCodeIcon className="w-4 h-4 text-white" />
                        </IconButton>
                      </Tooltip>
                      {/*<Tooltip content="Show Cards">
                        <IconButton
                          variant="text"
                          className="hover:bg-[#333333] rounded-full duration-300 opacity-50 hover:opacity-100"
                        >
                          <IdentificationIcon className="w-4 h-4 text-white" />
                        </IconButton>
                      </Tooltip>*/}
                      <Tooltip content="Edit">
                        <IconButton

                          onClick={()=>{
                            nav('/create-card', {state: card})
                          }}
                          variant="text"
                          className="hover:bg-[#333333] rounded-full duration-300 opacity-50 hover:opacity-100"
                        >
                          <PencilSquareIcon className="w-4 h-4 text-white" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip content="Delete">
                        <IconButton
                          onClick={()=>{handleDeleteClick(card.uploadId)}}
                          variant="text"
                          className="hover:bg-[#333333] rounded-full duration-300 opacity-50 hover:opacity-100"
                        >
                          <TrashIcon className="w-4 h-4 text-white" />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </CardBody>

      

    </Card>
  );
}
