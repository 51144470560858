import { useNavigate } from "react-router-dom";


const BlogCard=({blog})=>{

    const nav = useNavigate();

    return(
        <div className="hover:bg-[#fdfdf8] hover:scale-105 inline-block sm:w-full md:w-[300px] lg:w-[250px] xl:w-[380px] rounded-[6px] bg-white pb-4 shadow-xl  border-2 border-primary">
            <img src={blog.profileImageURL} className="rounded-t-[6px]" />
            <div className="ml-2 mt-2 text-[24px] font-bold text-gray-900">{blog.blogTitle}</div>
            <div className="ml-2 mt-1 text-[15px] text-slate-500">{blog.blogSubHeading}</div>
            <hr className="ml-2 mr-2 mt-5" />
            <p className="m-2 line-clamp-[9] text-justify text-gray-500">{blog.blogAbstract}</p>

            <div className="flex justify-center">
                <button className=" box-btn w-full mt-2 w-[95%] rounded-lg  px-7 py-2 text-white shadow" onClick={()=>{
                    nav('/view-blog',{state: blog})
                }}>Read More</button>
            </div>

        </div>
    )

};


export default BlogCard;